import {
  Constants,
  DocumentTypeCode,
  documentTypeCodeToResource,
  getBspId,
  resourceToDocumentTypeCode,
} from '@dx-ui/dx-common';
import { DataProvider } from 'react-admin';
import { PeppolAccessPoint } from '../shared/types';

/**
 * Provides operations to get information about a company.
 */

export interface CompanyModel {
  uid: string;
  name: string;
  identification: string;
  registrationNumber: string;
  corporateStock: CorporateStock;
  billingAddress: CompanyAddress;
  shippingAddress: CompanyAddress[];
  logisticAddress: CompanyAddress[];
  supplierCode: string;
  efactura?: boolean;
}

/**
 * Used by Company Service. Returned by the backend
 */
export interface CompanyAddress {
  name?: string;
  street?: string;
  additionalStreet?: string;
  buildingNumber?: string;
  city?: string;
  postalCode?: string;
  postBox?: string;
  bank?: string;
  financialAccount?: string;
  gln?: string;
  idCountry?: string;
}

export interface CorporateStock {
  amount: number;
  currency: string;
}

export interface CreationPolicy {
  conversion: boolean;
  scratch: boolean;
}

export interface CompanyFeatures {
  features?: string[];
}

export enum P2P_STATUS {
  WITH_P2P = 'WITH_P2P',
  NO_P2P = 'NO_P2P',
}
export class CompanyService {
  /**
   * Builds the service instance
   * @param {*} dataProvider React Admin data provider
   */
  constructor(public dataProvider: DataProvider) {}

  public static ERROR_GET_COMPANY_DETAILS =
    'Unable to get company details from DxRegistry';

  public static ERROR_GET_RELATIONS = 'Unable to get relations from DxRegistry';

  public static ERROR_GET_RECIPIENTS =
    'Unable to get recipients from DxRegistry';

  /**
   * gets company details
   * @param identification company fiscal code
   */
  async getDetails(identification?: string): Promise<CompanyModel> {
    // load company Details
    const payload = { identification };

    const response = await this.dataProvider[Constants.API_GET_COMPANY_DETAILS](
      Constants.RESOURCE_COMPANY,
      payload
    );

    const result: CompanyModel = response.data;

    if (result !== undefined) {
      this.setupAddresses(result);
    }

    return response.data;
  }

  /**
   * gets endpoint list by issuerIdentification or companyName
   * @param identification company fiscal code or companyName
   */
  async fetchEndpoints(identification?: string): Promise<PeppolAccessPoint[]> {
    // load endpoints list
    const payload = { identification };

    const response = await this.dataProvider[
      Constants.API_FETCH_ENDPOINTS_BY_ISSUER_IDENTIFICATION_OR_COMPANY_NAME
    ](Constants.RESOURCE_COMPANY, payload);

    return response.data;
  }

  /**
   * For reasons unknown to humanity drools checks crash if building number is not set
   */
  private setupAddresses(data: CompanyModel) {
    const addresses: CompanyAddress[] = [
      data.billingAddress,
      ...(data.shippingAddress || []),
      ...(data.logisticAddress || []),
    ].filter((addr) => !!addr);

    addresses.forEach((addr) => {
      if (!addr.buildingNumber) {
        addr.buildingNumber = '--';
      }
    });
  }

  /**
   * gets relations according to document type
   * @param docType document type code. Ex: INVOIC
   * @param identification company fiscal code (optional)
   */
  async getRelations(
    docType: DocumentTypeCode,
    identification?: string
  ): Promise<CompanyModel[]> {
    if (!docType) {
      throw new Error('Missing arg docType');
    }

    const payload = identification
      ? {
          identification,
          docType,
        }
      : {
          docType,
        };

    const response = await this.dataProvider[
      Constants.API_GET_COMPANY_RELATIONS
    ](Constants.RESOURCE_COMPANY, payload);

    return response.data;
  }

  async getRecipientsInfoForTemplateType(
    resource: string,
    identification?: string
  ): Promise<CompanyModel[]> {
    const payload = identification
      ? {
          bspId: getBspId(),
          documentType: resourceToDocumentTypeCode(resource),
          identification: identification,
        }
      : {
          bspId: getBspId(),
          documentType: resourceToDocumentTypeCode(resource),
        };

    const response = await this.dataProvider[
      Constants.API_GET_RECIPIENTS_FOR_TEMPLATE
    ](Constants.RESOURCE_COMPANY, payload);

    return response.data;
  }

  /**
   * Returns create and convert ability from creation policy
   */
  async fetchCreationPolicy(
    resource: string,
    recipientId: string
  ): Promise<CreationPolicy> {
    const result = await this.dataProvider.apiGet(
      `${Constants.RESOURCE_COMPANY}/creation/policy`,
      {
        documentType: documentTypeCodeToResource(
          resourceToDocumentTypeCode(resource)
        ), // filters WEB RESOURCES
        recipientId,
      }
    );
    return result.data; // default creation policy is managed by the B/E
  }

  /**
   * Filters the recipients which don't have a profile allowing creation from scratch
   * @param resource defines the targeted document
   * @param identification .
   * @returns an array of CompanyModel representing the recipient(s) accepting the creation from scratch.
   */
  public async getRecipientsForCreationFromScratch(
    resource: string,
    identification?: string
  ): Promise<CompanyModel[]> {
    let filteredRecipients: CompanyModel[] = [];
    const listOfRecipients: CompanyModel[] =
      await this.getRecipientsInfoForTemplateType(resource, identification);
    for (let index = 0; index < listOfRecipients.length; index++) {
      // Don't use ForEach because async method and callbacks are incompatibles
      const creationPolicy = await this.fetchCreationPolicy(
        resource,
        listOfRecipients[index].identification
      );
      if (creationPolicy?.scratch) {
        filteredRecipients.push(listOfRecipients[index]);
      }
    }
    return filteredRecipients;
  }

  /**
   * gets endpoint list by issuerIdentification or companyName
   * @param identification company fiscal code or companyName
   */
  async fetchCompanyDetails(identification: string): Promise<CompanyModel> {
    const params = new URLSearchParams({
      country: 'RO',
      vatCode: identification,
    });
    const result = await fetch(
      `/${Constants.RESOURCE_ACCOUNT}/company-info?${params}`,
      {
        credentials: 'include',
      }
    )
      .then((response: Response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .catch((err) => {
        return null;
      });
    return result;
  }

  /**
   * Returns create and convert ability from creation policy
   */
  async fetchCompanyFeatures(): Promise<CompanyFeatures> {
    const result = await fetch(`/${Constants.RESOURCE_COMPANY}/features`, {
      credentials: 'include',
    })
      .then((response: Response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .catch((err) => {
        return null;
      });
    return result;
  }

  /**
   * Returns P2P status from buyer policy
   */
  async fetchP2pStatus(
    resource: string,
    recipientId: string
  ): Promise<P2P_STATUS> {
    const result = await this.dataProvider.apiGet(
      `${Constants.RESOURCE_COMPANY}/p2p/policy`,
      {
        documentType: documentTypeCodeToResource(
          resourceToDocumentTypeCode(resource)
        ), // filters WEB RESOURCES
        recipientId,
      }
    );
    return result.data;
  }
}
