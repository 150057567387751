import {
  DxTheme,
  EmptyValue,
  formatNumericValue,
  GenericLabeledDateField,
  Metadata,
  PriceTextField,
  RegulatorExtraDetailsType,
  SimpleLabeledField,
  Ubl,
} from '@dx-ui/dx-common';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { cloneDeep, get, set } from 'lodash';
import PropTypes from 'prop-types';
import { useDataProvider } from 'ra-core';
import React, { ComponentType, useEffect, useMemo, useState } from 'react';
import {
  ComponentPropType,
  Datagrid,
  FunctionField,
  ListContextProvider,
  Loading,
  TextField,
  useLocale,
  useResourceContext,
  useTranslate,
} from 'react-admin';
import {
  AlfrescoDocumentService,
  DataHelpers,
  DocumentServiceFactory,
  InvoiceService,
  InvoiceServiceCreator,
  LineProcessor,
} from '../../services';
import { FieldTypes } from '../../shared';
import { P2pData, P2pPreviewData, P2pUblContent } from '../../shared/types';
import TaxSummaryPanel from '../../shared/webForms/fields/TaxSummaryPanel';
import TaxTotals from '../../shared/webForms/fields/TaxTotals';
import { CommaSeparator } from '../../shared/webForms/utils';
import { P2pUtils } from '../common/P2pUtils';
import {
  AddressField,
  getAccountingCustomerAddress,
  getAccountingSupplierAddress,
  getDeliveryLocationAddress,
} from '../common/PreviewAddress';
import { useCellStyle, useCommonPreviewStyles } from '../common/PreviewStyles';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    exchangeRate: {
      marginRight: 4,
    },
  }),
  { name: 'InvoicePreview' }
);

interface PropsModel {
  record: P2pData;
  basePath?: string;
  toolbar?: ComponentType<any>;
  resource?: string;
}

const InvoicePreview = (props: PropsModel) => {
  const { basePath, record, toolbar } = props;

  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const classes = useStyles(props);
  const commonClasses = useCommonPreviewStyles();
  const cellStyleClasses = useCellStyle();
  const resource = useResourceContext(props);
  const locale = useLocale();

  const [data, setData] = useState<P2pPreviewData | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const invoiceService = useMemo(
    () =>
      DocumentServiceFactory.create(
        InvoiceServiceCreator,
        dataProvider
      ) as InvoiceService,
    [dataProvider]
  );

  useEffect(() => {
    const getData = async () => {
      const nodeId: string | undefined = record.id;

      try {
        let properties: any = {};
        let ublProperties: any = {};
        let lines: any[];

        properties = cloneDeep(record?.properties ?? {});

        if (record?.ublProperties && record?.lines) {
          // Updated draft not yet saved
          ublProperties = cloneDeep(record?.ublProperties);
          lines = cloneDeep(record?.lines);
          // Document allowance and charge pre-formatting
          const documentLevelCharge = get(record, FieldTypes.DOC_LEVEL_CHARGES);
          const documentLevelDiscount = get(
            record,
            FieldTypes.DOC_LEVEL_DISCOUNTS
          );
          if (documentLevelCharge?.length > 0) {
            if (ublProperties.allowanceCharge === undefined) {
              set(ublProperties, 'allowanceCharge', []);
            }
            documentLevelCharge.forEach((c) => {
              ublProperties.allowanceCharge.push(c);
            });
          }
          if (documentLevelDiscount?.length > 0) {
            if (ublProperties.allowanceCharge === undefined) {
              set(ublProperties, 'allowanceCharge', []);
            }
            documentLevelDiscount.forEach((c) => {
              ublProperties.allowanceCharge.push(c);
            });
          }
        } else if (nodeId) {
          let results: P2pUblContent;
          if (AlfrescoDocumentService.isWebDocument(record)) {
            // Draft saved preview
            results = await invoiceService.loadDraft(nodeId);
            // Document allowance and charge pre-formatting
            const documentLevelCharge = get(
              results,
              FieldTypes.DOC_LEVEL_CHARGES
            );
            const documentLevelDiscount = get(
              results,
              FieldTypes.DOC_LEVEL_DISCOUNTS
            );
            if (documentLevelCharge?.length > 0) {
              if (results.ublProperties.allowanceCharge === undefined) {
                set(results, 'ublProperties.allowanceCharge', []);
              }
              documentLevelCharge.forEach((c) => {
                results.ublProperties.allowanceCharge.push(c);
              });
            }
            if (documentLevelDiscount?.length > 0) {
              if (results.ublProperties.allowanceCharge === undefined) {
                set(results, 'ublProperties.allowanceCharge', []);
              }
              documentLevelDiscount.forEach((c) => {
                results.ublProperties.allowanceCharge.push(c);
              });
            }
          } else {
            // Processed invoice preview
            results = await invoiceService.load(nodeId);
          }

          ublProperties = results?.ublProperties;
          lines = results?.lines;
        } else {
          throw new Error('record id cannot be null!');
        }
        const currencyID = DataHelpers.getCurrencyID({ properties });
        const linesView = P2pUtils.convertInvoiceLines2ViewObject(
          lines,
          currencyID
        );

        const previewData: P2pPreviewData = {
          id: nodeId,
          properties,
          ublProperties,
          linesView,
        };

        setData(previewData);
        setLoading(false);
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error);
        setError(error);
        setLoading(false);
      }
    };
    if (!loading && !data && !error) {
      setLoading(true);
      getData();
    }
  }, [data, dataProvider, error, invoiceService, loading, record]);

  if (error) {
    return (
      <Typography variant='h5'>
        {translate('dxMessages.error_messages.unable_to_display')}
      </Typography>
    );
  } else if (loading || !data) {
    return <Loading />;
  } else {
    const taxSummaryTable = get(data.ublProperties, Ubl.taxSubtotal) || [];
    const currencyID = DataHelpers.getCurrencyID(data);
    const lineIds = Object.keys(data?.linesView ?? []);
    const documentLevelAllowanceCharge = data?.ublProperties.allowanceCharge;
    const exchangeRate = get(data, Ubl.paymentExchangeRateCalculationRate);
    const excisePayerCode = get(data, Ubl.AccountingSupplierTaxSchemeCompanyID);
    const projectReferenceID = get(data, Ubl.projectReferenceID);
    const profileID = get(data, Ubl.profileID);
    const peppolInvoice: boolean = profileID
      ? profileID.indexOf('peppol') > 0
      : false;
    const anafOnlyInvoice: boolean =
      DataHelpers.regulatorExtraDetails(data) ===
      RegulatorExtraDetailsType.ANAF_ONLY;
    const accessPoint = `${get(
      data,
      'ublProperties.accountingCustomerParty.party.endpointID.schemeID'
    )}:${get(
      data,
      'ublProperties.accountingCustomerParty.party.endpointID.value'
    )}`;
    const documentDiscount: any[] = [];
    const documentCharge: any[] = [];
    let documentDiscountLineIds: string[] = [];
    let documentChargeLineIds: string[] = [];
    if (documentLevelAllowanceCharge) {
      documentLevelAllowanceCharge.forEach((e) => {
        if (
          e.chargeIndicator?.value === true ||
          e.charge_reason_code?.value !== undefined
        ) {
          documentCharge.push(e);
        } else {
          documentDiscount.push(e);
        }
      });
      documentDiscountLineIds = Object.keys(documentDiscount ?? []);
      documentChargeLineIds = Object.keys(documentCharge ?? []);
    }
    const taxRepresentativeParty = get(
      data.ublProperties,
      'taxRepresentativeParty'
    );

    return (
      <>
        <Paper square classes={{ root: commonClasses.paper }}>
          <Grid container justify='center'>
            <Grid item>
              <Typography variant='h6' style={{ fontSize: '2em' }}>
                {translate('dxMessages.invoices.document_label')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container classes={{ container: commonClasses.section }}>
            <Grid item xs={2}>
              <SimpleLabeledField
                label={translate('dxMessages.invoices.invoiceNumber')}
                data={get(data.properties, Metadata.documentId)}
                className={Metadata.documentId}
              />
            </Grid>
            <Grid item xs={3}>
              <GenericLabeledDateField
                label={translate('dxMessages.headers.issueDate')}
                id={Metadata.issueDate}
                record={data}
                className={Metadata.issueDate}
              />
            </Grid>
            <Grid item xs={2}>
              <GenericLabeledDateField
                label={translate('dxMessages.invoices.due_date')}
                id={Ubl.dueDate}
                record={data}
                className={Metadata.dueDate}
              />
            </Grid>
            <Grid item xs={2}>
              <GenericLabeledDateField
                label={translate('dxMessages.headers.deliveryDate')}
                id={Ubl.deliveryDate}
                record={data}
                className={'deliveryDate'}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            classes={{ container: commonClasses.graySection }}
          >
            <Grid item container direction='column' xs={6}>
              <Grid item>
                <SimpleLabeledField
                  label={translate('dxMessages.headers.supplier')}
                  data={get(data.properties, Metadata.issuerName)}
                  className={Metadata.issuerName}
                  oneLine
                />
              </Grid>
              <Grid item>
                <SimpleLabeledField
                  label={translate('dxMessages.headers.supplierId')}
                  data={get(data.properties, Metadata.issuerId)}
                  className={Metadata.issuerId}
                  oneLine
                />
              </Grid>
              <Grid item>
                <SimpleLabeledField
                  label={translate('dxMessages.headers.supplierReceiverCode')}
                  data={get(data, Ubl.accountingSupplierCustomerAssignedID)}
                  oneLine
                  className={'accountingSupplierCustomerAssignedID'}
                />
              </Grid>
              {excisePayerCode && (
                <Grid item>
                  <SimpleLabeledField
                    label={translate('dxMessages.headers.excisePayerCode')}
                    data={excisePayerCode}
                    oneLine
                    className={'excisePayerCode'}
                  />
                </Grid>
              )}
              <Grid item>
                <SimpleLabeledField
                  label={translate('dxMessages.invoices.iban')}
                  data={get(data, Ubl.payeeIban)}
                  className={'issuerIban'}
                  oneLine
                />
              </Grid>
              <Grid item>
                <SimpleLabeledField
                  label={translate('dxMessages.invoices.bank')}
                  data={get(data, Ubl.payeeBank)}
                  className={'issuerBank'}
                  oneLine
                />
              </Grid>
              <Grid item>
                <AddressField
                  address={getAccountingSupplierAddress(data)}
                  className={'accountingSupplierAddress'}
                />
              </Grid>
            </Grid>
            <Grid item container direction='column' xs={6}>
              <Grid item>
                <SimpleLabeledField
                  label={translate('dxMessages.headers.buyer')}
                  data={get(data.properties, Metadata.recipientName)}
                  className={Metadata.recipientName}
                  oneLine
                />
              </Grid>
              <Grid item>
                <SimpleLabeledField
                  label={translate('dxMessages.headers.buyerId')}
                  data={get(data.properties, Metadata.recipientId)}
                  className={Metadata.recipientId}
                  oneLine
                />
              </Grid>
              {peppolInvoice && (
                <Grid item>
                  <SimpleLabeledField
                    label={translate('dxMessages.peppol.accessPoint.title')}
                    data={accessPoint}
                    className={'peppolEndpoint'}
                    oneLine
                  />
                </Grid>
              )}
              {!peppolInvoice && (
                <Grid item>
                  <SimpleLabeledField
                    label={translate('dxMessages.invoices.iban')}
                    data={get(data, Ubl.payerIban)}
                    className={'recipientIban'}
                    oneLine
                  />
                </Grid>
              )}
              {!peppolInvoice && (
                <Grid item>
                  <SimpleLabeledField
                    label={translate('dxMessages.invoices.bank')}
                    data={get(data, Ubl.payerBank)}
                    className={'recipientBank'}
                    oneLine
                  />
                </Grid>
              )}
              <Grid item>
                <AddressField
                  address={getAccountingCustomerAddress(data)}
                  className={'accountingCustomerAddress'}
                />
              </Grid>
            </Grid>
          </Grid>
          {taxRepresentativeParty && (
            <Grid
              container
              direction='row'
              classes={{ container: commonClasses.graySection }}
            >
              <Grid item container direction='column' xs={6} />
              <Grid item container direction='column' xs={6}>
                <Grid item>
                  <SimpleLabeledField
                    label={translate(
                      'dxMessages.headers.taxRepresentativeName'
                    )}
                    data={get(taxRepresentativeParty, 'partyName.name.value')}
                    className={'TaxRepresentativeName'}
                    oneLine
                  />
                </Grid>
                <Grid item>
                  <SimpleLabeledField
                    label={translate(
                      'dxMessages.headers.taxRepresentativeCompanyID'
                    )}
                    data={get(
                      taxRepresentativeParty,
                      'partyTaxScheme.companyID.value'
                    )}
                    className={'taxRepresentativeCompanyID'}
                    oneLine
                  />
                </Grid>
                <Grid item>
                  <AddressField
                    address={get(taxRepresentativeParty, 'postalAddress')}
                    className={'taxRepresentativeAddress'}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid container classes={{ container: commonClasses.section }}>
            <Grid item container xs={6}>
              <Grid
                item
                container
                direction='row'
                style={{ alignItems: 'baseline ' }}
              >
                <Grid item>
                  <SimpleLabeledField
                    label={translate('dxMessages.invoices.initialInvoice')}
                    data={get(data, Ubl.invoiceDocumentReferenceId)}
                    oneLine
                    className={'referenceInvoiceId'}
                  />
                </Grid>
                <CommaSeparator />
                <Grid item>
                  <GenericLabeledDateField
                    label={translate('dxMessages.headers.issueDate')}
                    id={Ubl.invoiceDocumentReferenceIssueDate}
                    record={data}
                    oneLine
                    className={'referenceInvoiceIssueDate'}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction='row'
                style={{ alignItems: 'baseline ' }}
              >
                <Grid item>
                  <SimpleLabeledField
                    label={translate('dxMessages.headers.orderId')}
                    data={get(data.properties, Metadata.orderId)}
                    oneLine
                    className={Metadata.orderId}
                  />
                </Grid>
                <CommaSeparator />
                <Grid item>
                  <GenericLabeledDateField
                    label={translate('dxMessages.headers.orderDate')}
                    id={Metadata.orderDate}
                    record={data}
                    oneLine
                    className={Metadata.orderDate}
                  />
                </Grid>
              </Grid>
              <Grid item container>
                <SimpleLabeledField
                  label={translate('dxMessages.headers.deliveryLocationName')}
                  data={
                    get(data, Ubl.deliveryLocationName)
                      ? get(data, Ubl.deliveryLocationName)
                      : get(data.properties, Metadata.locationName)
                  }
                  oneLine
                  className={Metadata.locationName}
                />
              </Grid>
              <Grid item container>
                <SimpleLabeledField
                  label={translate('dxMessages.headers.gln')}
                  data={get(data, Ubl.deliveryLocationGLN)}
                  oneLine
                  className={'deliveryLocationGLN'}
                />
              </Grid>
              <Grid item container>
                <AddressField
                  label={translate('dxMessages.headers.locationAddress')}
                  address={getDeliveryLocationAddress(data)}
                  className={'deliveryLocationAddress'}
                />
              </Grid>
            </Grid>
            <Grid item container xs={6} alignContent='flex-start'>
              <Grid
                item
                container
                direction='row'
                style={{ alignItems: 'baseline ' }}
              >
                <Grid item>
                  <SimpleLabeledField
                    label={translate('dxMessages.headers.despatchAdviceId')}
                    data={get(data.properties, Metadata.despatchAdviceId)}
                    oneLine
                    className={Metadata.despatchAdviceId}
                  />
                </Grid>
                <CommaSeparator />
                <Grid item>
                  <GenericLabeledDateField
                    label={translate('dxMessages.headers.despatchAdviceDate')}
                    id={Metadata.despatchAdviceDate}
                    record={data}
                    oneLine
                    className={Metadata.despatchAdviceDate}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction='row'
                style={{ alignItems: 'baseline ' }}
              >
                <Grid item>
                  <SimpleLabeledField
                    label={translate('dxMessages.headers.receiptAdviceId')}
                    data={get(data.properties, Metadata.receiptAdviceId)}
                    oneLine
                    className={Metadata.receiptAdviceId}
                  />
                </Grid>
                <CommaSeparator />
                <Grid item>
                  <GenericLabeledDateField
                    label={translate('dxMessages.headers.receiptAdviceDate')}
                    id={Metadata.receiptAdviceDate}
                    record={data}
                    oneLine
                    className={Metadata.receiptAdviceDate}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction='row'
                style={{ alignItems: 'baseline ' }}
              >
                <Grid item>
                  <SimpleLabeledField
                    label={translate('dxMessages.headers.contractId')}
                    data={get(data.properties, Metadata.contractId)}
                    oneLine
                    className={Metadata.contractId}
                  />
                </Grid>
                <CommaSeparator />
                <Grid item>
                  <GenericLabeledDateField
                    label={translate('dxMessages.headers.contractDate')}
                    id={Metadata.contractDate}
                    record={data}
                    oneLine
                    className={Metadata.contractDate}
                  />
                </Grid>
              </Grid>
              {peppolInvoice && (
                <Grid item container>
                  <SimpleLabeledField
                    label={translate('dxMessages.headers.projectReferenceID')}
                    data={projectReferenceID}
                    oneLine
                    className={'projectReferenceID'}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          {documentDiscount.length > 0 && (
            <Grid
              container
              classes={{ container: commonClasses.section }}
              direction='column'
            >
              <Grid item>
                <Typography>
                  {translate('dxMessages.invoices.documentLevelDiscounts')}
                </Typography>
              </Grid>
              <Grid item>
                <ListContextProvider
                  value={{
                    ids: documentDiscountLineIds,
                    data: documentDiscount,
                    currentSort: {},
                    resource,
                    selectedIds: [],
                  }}
                >
                  <Datagrid
                    classes={{
                      row: commonClasses.row,
                      rowCell: commonClasses.linesCell,
                      headerCell: commonClasses.headerCell,
                      tbody: commonClasses.tbody,
                    }}
                  >
                    <PriceTextField
                      source='amount.value'
                      label='dxMessages.headers.discountAmount'
                      sortable={false}
                      headerClassName='alignRight'
                      cellClassName='alignRight'
                    />

                    <FunctionField
                      source='taxCategory.percent.value'
                      label='dxMessages.headers.vatPercent'
                      sortable={false}
                      render={(record) => {
                        const value =
                          get(record, 'taxCategory.percent.value') ||
                          get(record, 'taxCategory[0].percent.value');
                        return (
                          <Typography>
                            {!isNaN(value) && value !== null
                              ? `${value}%`
                              : EmptyValue}
                          </Typography>
                        );
                      }}
                    />
                    <FunctionField
                      source='taxCategory.id.value'
                      label='dxMessages.invoices.tax_category_code'
                      sortable={false}
                      render={(record) => {
                        const value =
                          get(record, 'taxCategory.id.value') ||
                          get(record, 'taxCategory[0].id.value');
                        let displayScheme: string;
                        switch (value) {
                          case 'S':
                            displayScheme = translate(
                              'dxMessages.invoices.taxCategory.standard'
                            );
                            break;
                          case 'AE':
                            displayScheme = translate(
                              'dxMessages.invoices.taxCategory.reverse'
                            );
                            break;
                          case 'E':
                            displayScheme = translate(
                              'dxMessages.invoices.taxCategory.exempt'
                            );
                            break;
                          case 'Z':
                            displayScheme = translate(
                              'dxMessages.invoices.taxCategory.zeroVat'
                            );
                            break;
                          case 'G':
                            displayScheme = translate(
                              'dxMessages.invoices.taxCategory.freeExport'
                            );
                            break;
                          case 'O':
                            displayScheme = translate(
                              'dxMessages.invoices.taxCategory.outsideOfScope'
                            );
                            break;
                          case 'K':
                            displayScheme = translate(
                              'dxMessages.invoices.taxCategory.EEAIntraCommunity'
                            );
                            break;
                          default:
                            displayScheme = translate(
                              `dxMessages.invoices.taxCategory.${value}`,
                              { _: value ? value : EmptyValue }
                            );
                        }
                        return (
                          <Typography>
                            {displayScheme !== null
                              ? displayScheme
                              : EmptyValue}
                          </Typography>
                        );
                      }}
                      style={{ display: 'block', width: '12em' }}
                    />
                    <FunctionField
                      source='discount_reason_code.value'
                      label='dxMessages.invoices.tax_reason_code'
                      sortable={false}
                      render={(record) => {
                        const value =
                          get(record, 'discount_reason_code.value') ||
                          get(record, 'allowanceChargeReasonCode.value');
                        return (
                          <Typography>
                            {value
                              ? translate(
                                  `dxMessages.invoices.discountReasonCode.${value}`,
                                  { _: value ? value : EmptyValue }
                                )
                              : EmptyValue}
                          </Typography>
                        );
                      }}
                      style={{ display: 'block', width: '12em' }}
                    />
                  </Datagrid>
                </ListContextProvider>
              </Grid>
            </Grid>
          )}
          {documentCharge.length > 0 && (
            <Grid
              container
              classes={{ container: commonClasses.section }}
              direction='column'
            >
              <Grid item>
                <Typography>
                  {translate('dxMessages.invoices.documentLevelCharges')}
                </Typography>
              </Grid>
              <Grid item>
                <ListContextProvider
                  value={{
                    ids: documentChargeLineIds,
                    data: documentCharge,
                    currentSort: {},
                    resource,
                    selectedIds: [],
                  }}
                >
                  <Datagrid
                    classes={{
                      row: commonClasses.row,
                      rowCell: commonClasses.linesCell,
                      headerCell: commonClasses.headerCell,
                      tbody: commonClasses.tbody,
                    }}
                  >
                    <PriceTextField
                      source='amount.value'
                      label='dxMessages.headers.taxAmount'
                      sortable={false}
                      headerClassName='alignRight'
                      cellClassName='alignRight'
                    />

                    <FunctionField
                      source='taxCategory.percent.value'
                      label='dxMessages.headers.vatPercent'
                      sortable={false}
                      render={(record) => {
                        const value =
                          get(record, 'taxCategory.percent.value') ||
                          get(record, 'taxCategory[0].percent.value');
                        return (
                          <Typography>
                            {!isNaN(value) && value !== null
                              ? `${value}%`
                              : EmptyValue}
                          </Typography>
                        );
                      }}
                    />
                    <FunctionField
                      source='taxCategory.id.value'
                      label='dxMessages.invoices.tax_category_code'
                      sortable={false}
                      render={(record) => {
                        const value =
                          get(record, 'taxCategory.id.value') ||
                          get(record, 'taxCategory[0].id.value');
                        let displayScheme: string;
                        switch (value) {
                          case 'S':
                            displayScheme = translate(
                              'dxMessages.invoices.taxCategory.standard'
                            );
                            break;
                          case 'AE':
                            displayScheme = translate(
                              'dxMessages.invoices.taxCategory.reverse'
                            );
                            break;
                          case 'E':
                            displayScheme = translate(
                              'dxMessages.invoices.taxCategory.exempt'
                            );
                            break;
                          case 'Z':
                            displayScheme = translate(
                              'dxMessages.invoices.taxCategory.zeroVat'
                            );
                            break;
                          case 'G':
                            displayScheme = translate(
                              'dxMessages.invoices.taxCategory.freeExport'
                            );
                            break;
                          case 'O':
                            displayScheme = translate(
                              'dxMessages.invoices.taxCategory.outsideOfScope'
                            );
                            break;
                          case 'K':
                            displayScheme = translate(
                              'dxMessages.invoices.taxCategory.EEAIntraCommunity'
                            );
                            break;
                          default:
                            displayScheme = translate(
                              `dxMessages.invoices.taxCategory.${value}`,
                              { _: value ? value : EmptyValue }
                            );
                        }
                        return (
                          <Typography>
                            {displayScheme !== null
                              ? displayScheme
                              : EmptyValue}
                          </Typography>
                        );
                      }}
                      style={{ display: 'block', width: '12em' }}
                    />
                    <FunctionField
                      source='charge_reason_code.value'
                      label='dxMessages.invoices.tax_reason_code'
                      sortable={false}
                      render={(record) => {
                        const value =
                          get(record, 'charge_reason_code.value') ||
                          get(record, 'allowanceChargeReasonCode.value');
                        return (
                          <Typography>
                            {value
                              ? translate(
                                  `dxMessages.invoices.chargeReasonCode.${value}`,
                                  { _: value ? value : EmptyValue }
                                )
                              : EmptyValue}
                          </Typography>
                        );
                      }}
                      style={{ display: 'block', width: '12em' }}
                    />
                  </Datagrid>
                </ListContextProvider>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            direction='row'
            classes={{ container: commonClasses.section }}
          >
            <Grid
              item
              container
              direction='column'
              classes={{ container: commonClasses.section }}
            >
              <Grid container item>
                <Grid item container xs={2} justify='flex-start'>
                  <SimpleLabeledField
                    label={translate('dxMessages.headers.currency')}
                    data={currencyID}
                    oneLine
                    className={Metadata.currency}
                  />
                </Grid>
                {exchangeRate !== undefined && (
                  <Grid item container xs={10} justify='flex-end'>
                    <Grid item>
                      <SimpleLabeledField
                        label={translate(
                          'dxMessages.headers.paymentExchangeRateCalculationRate'
                        )}
                        data={exchangeRate}
                        oneLine
                        className={`paymentExchangeRateCalculationRate ${classes.exchangeRate}`}
                      />
                    </Grid>
                    <Grid item>
                      <SimpleLabeledField
                        label={translate(
                          'dxMessages.headers.paymentExchangeRateSourceCurrencyCode'
                        )}
                        data={get(
                          data,
                          Ubl.paymentExchangeRateSourceCurrencyCode
                        )}
                        oneLine
                        className={`paymentExchangeRateSourceCurrencyCode ${classes.exchangeRate}`}
                      />
                    </Grid>
                    <Grid item>
                      <SimpleLabeledField
                        label={translate(
                          'dxMessages.headers.paymentExchangeRateTargetCurrencyCode'
                        )}
                        data={get(
                          data,
                          Ubl.paymentExchangeRateTargetCurrencyCode
                        )}
                        oneLine
                        className={'paymentExchangeRateTargetCurrencyCode'}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <ListContextProvider
                  value={{
                    ids: lineIds,
                    data: data.linesView,
                    currentSort: { field: 'nr', order: 'ASC' },
                    resource,
                    selectedIds: [],
                  }}
                >
                  <Datagrid
                    classes={{
                      row: commonClasses.row,
                      rowCell: commonClasses.linesCell,
                      headerCell: commonClasses.headerCell,
                      tbody: commonClasses.tbody,
                    }}
                  >
                    <TextField
                      source='nr'
                      label='dxMessages.headers.number'
                      sortable={false}
                    />
                    <TextField
                      source={Ubl.codeClient}
                      label='dxMessages.headers.codeClient'
                      sortable={false}
                      style={{ minWidth: '8em' }}
                    />
                    <TextField
                      source={Ubl.codeSupplier}
                      label='dxMessages.headers.codeSupplier'
                      sortable={false}
                      style={{ minWidth: '8em' }}
                    />
                    <TextField
                      source={Ubl.codeStandard}
                      label='dxMessages.headers.codeStandard'
                      sortable={false}
                      style={{ minWidth: '8em' }}
                    />
                    <TextField
                      source={Ubl.description}
                      label='dxMessages.headers.description'
                      sortable={false}
                      classes={{ root: cellStyleClasses.cellWrapped }}
                      style={{ minWidth: '8em' }}
                    />
                    <TextField
                      source={Ubl.quantityInvoice}
                      label='dxMessages.headers.quantity'
                      sortable={false}
                    />
                    <TextField
                      source={Ubl.unitCodeInvoice}
                      label='dxMessages.headers.um'
                      sortable={false}
                    />
                    <PriceTextField
                      source={Ubl.price}
                      label='dxMessages.headers.price'
                      sortable={false}
                      headerClassName='alignRight'
                      cellClassName='alignRight'
                    />
                    <FunctionField
                      source={Ubl.discountMultiplierFactorNumeric}
                      label='dxMessages.headers.discount'
                      sortable={false}
                      render={(record) => {
                        const value = get(
                          record,
                          Ubl.discountMultiplierFactorNumeric
                        );
                        return (
                          <Typography>
                            {!isNaN(value) && value !== null
                              ? `${value}%`
                              : EmptyValue}
                          </Typography>
                        );
                      }}
                      style={{ minWidth: '3em' }}
                    />
                    <FunctionField
                      source={'taxType'}
                      label='dxMessages.headers.taxType'
                      sortable={false}
                      render={(record) => {
                        if (LineProcessor.isLineSGR(record as any)) {
                          return (
                            <Typography>
                              {translate('dxMessages.headers.sgrTax')}
                            </Typography>
                          );
                        } else if (
                          DataHelpers.getGreenTaxValue(
                            DataHelpers.getGreenTaxNode(record as any)
                          )
                        ) {
                          return (
                            <Typography>
                              {translate('dxMessages.invoices.taxes.GT_Amount')}
                            </Typography>
                          );
                        } else if (
                          DataHelpers.getSugarTaxValue(
                            DataHelpers.getSugarTaxNode(record as any)
                          )
                        ) {
                          return (
                            <Typography>
                              {translate(
                                'dxMessages.invoices.taxes.SGT_Amount'
                              )}
                            </Typography>
                          );
                        } else {
                          return <Typography>{EmptyValue}</Typography>;
                        }
                      }}
                      style={{ minWidth: '3em' }}
                    />
                    <FunctionField
                      source={'tax'}
                      label='dxMessages.invoices.taxes.label'
                      textAlign='right'
                      sortable={false}
                      render={(record) => {
                        if (LineProcessor.isLineSGR(record as any)) {
                          const value = get(
                            record,
                            'allowanceCharge[2].amount.value'
                          );
                          return (
                            <Typography>
                              {value
                                ? formatNumericValue(value, locale)
                                : EmptyValue}
                            </Typography>
                          );
                        } else if (
                          DataHelpers.getGreenTaxValue(
                            DataHelpers.getGreenTaxNode(record as any)
                          )
                        ) {
                          const value = get(record, Ubl.greenTaxPerUnitAmount);
                          return (
                            <Typography>
                              {value
                                ? formatNumericValue(value, locale)
                                : EmptyValue}
                            </Typography>
                          );
                        } else if (
                          DataHelpers.getSugarTaxValue(
                            DataHelpers.getSugarTaxNode(record as any)
                          )
                        ) {
                          const value = get(record, Ubl.sugarTaxPerUnitAmount);
                          return (
                            <Typography>
                              {value
                                ? formatNumericValue(value, locale)
                                : EmptyValue}
                            </Typography>
                          );
                        } else {
                          return <Typography>{EmptyValue}</Typography>;
                        }
                      }}
                      style={{ minWidth: '3em' }}
                    />
                    <FunctionField
                      source={Ubl.vatPercentage}
                      label='dxMessages.headers.vat'
                      sortable={false}
                      render={(record) => {
                        const value = get(record, Ubl.vatPercentage);
                        return (
                          <Typography>
                            {!isNaN(value) && value !== null
                              ? `${value}%`
                              : EmptyValue}
                          </Typography>
                        );
                      }}
                      style={{ minWidth: '3em' }}
                    />
                    {(peppolInvoice || anafOnlyInvoice) && (
                      <FunctionField
                        source={Ubl.taxSchemeName}
                        label='dxMessages.invoices.tax_category_code'
                        sortable={false}
                        render={(record) => {
                          const value =
                            get(record, Ubl.taxSchemeName) ||
                            get(
                              record,
                              'taxTotal[0].taxSubtotal[0].taxCategory.id.value'
                            );
                          let displayScheme: string;
                          switch (value) {
                            case 'S':
                              displayScheme = translate(
                                'dxMessages.invoices.taxCategory.standard'
                              );
                              break;
                            case 'AE':
                              displayScheme = translate(
                                'dxMessages.invoices.taxCategory.reverse'
                              );
                              break;
                            case 'E':
                              displayScheme = translate(
                                'dxMessages.invoices.taxCategory.exempt'
                              );
                              break;
                            case 'Z':
                              displayScheme = translate(
                                'dxMessages.invoices.taxCategory.zeroVat'
                              );
                              break;
                            case 'G':
                              displayScheme = translate(
                                'dxMessages.invoices.taxCategory.freeExport'
                              );
                              break;
                            case 'O':
                              displayScheme = translate(
                                'dxMessages.invoices.taxCategory.outsideOfScope'
                              );
                              break;
                            case 'K':
                              displayScheme = translate(
                                'dxMessages.invoices.taxCategory.EEAIntraCommunity'
                              );
                              break;
                            default:
                              displayScheme = translate(
                                `dxMessages.invoices.taxCategory.${value}`,
                                { _: value ? value : EmptyValue }
                              );
                          }
                          return (
                            <Typography>
                              {displayScheme !== null
                                ? displayScheme
                                : EmptyValue}
                            </Typography>
                          );
                        }}
                        style={{ minWidth: '3em' }}
                      />
                    )}
                    {(peppolInvoice || anafOnlyInvoice) && (
                      <FunctionField
                        source={Ubl.taxExemptionReasonCode}
                        label='dxMessages.invoices.tax_reason_code'
                        sortable={false}
                        render={(record) => {
                          const value = get(record, Ubl.taxExemptionReasonCode);
                          return (
                            <Typography>
                              {value ? value : EmptyValue}
                            </Typography>
                          );
                        }}
                      />
                    )}
                    <PriceTextField
                      source={Ubl.totalWithoutVat}
                      label='dxMessages.headers.totalWithoutVat'
                      sortable={false}
                      headerClassName='alignRight'
                      cellClassName='alignRight'
                    />
                  </Datagrid>
                </ListContextProvider>
              </Grid>
              <Grid item container justify='flex-end'>
                <Grid item>
                  <TaxTotals data={data} />
                </Grid>
              </Grid>
              {taxSummaryTable.length > 0 && (
                <Grid item container justify='flex-end'>
                  <Grid item>
                    <TaxSummaryPanel data={data} />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
        {toolbar &&
          React.createElement(toolbar, {
            record: data,
            resource,
            basePath,
          })}
      </>
    );
  }
};

InvoicePreview.propTypes = {
  toolbar: ComponentPropType,
  record: PropTypes.object.isRequired,
  basePath: PropTypes.string,
  resource: PropTypes.string,
};

export default InvoicePreview;
