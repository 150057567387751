import { resolveDateRange } from '@dx-ui/dx-common';
import { first, get, memoize as lodashMemoize } from 'lodash';
import moment from 'moment-timezone';
import {
  CompareTypeOperator,
  CompareValidator,
  CompareValidatorTargetToday,
  IsSetValidator,
  RegexValidator,
  Template,
  ValidationEmitter,
} from '../../../shared/types';
import {
  getFieldFromTemplate,
  getLineIndexFromSource,
  getSourceField,
} from '../utils';

export interface ValidationErrorMessageWithArgs {
  message: string;
  args: {
    [key: string]: ValidationErrorMessageWithArgs | any;
  };
}

export type ValidationErrorMessage = string | ValidationErrorMessageWithArgs;

export type Validator = (
  value: any,
  values: any,
  props: any
) => ValidationErrorMessage | null | undefined;
interface MessageFuncParams {
  args: any;
  value: any;
  values: any;
}

type MessageFunc = (params: MessageFuncParams) => ValidationErrorMessage;

// If we define validation functions directly in JSX, it will
// result in a new function at every render, and then trigger infinite re-render.
// Hence, we memoize every built-in validator to prevent a "Maximum call stack" error.
const memoize: any = (fn: any) =>
  lodashMemoize(fn, (...args) => JSON.stringify(args));

const getMessage = (
  message: string | MessageFunc,
  messageArgs: any,
  value: any,
  values: any
) =>
  typeof message === 'function'
    ? message({
        args: messageArgs,
        value,
        values,
      })
    : messageArgs
    ? {
        message,
        args: messageArgs,
      }
    : message;

// Alternative to a usage of eval when calling function by string name
// This Object helps to match a validate function referenced by its name in the JSON file
export const availableValidations = {
  GreaterThan: (template: Template, params: any[], message: string) =>
    compareAny(template, first(params), message, CompareTypeOperator.GT),
  LessThan: (template: Template, params: any[], message: string) =>
    compareAny(template, first(params), message, CompareTypeOperator.LT),
};

/**
 * Checks if the number has the maximum amount of decimals
 */
export const maxDecimals = memoize(
  (decimals, message = 'dxMessages.error_messages.max_decimals') =>
    (value, values) => {
      // value can be number of string or empty
      if (value === undefined || value === null || value === '') {
        return;
      }

      //check if number
      const valueAsNumber = parseFloat(value);
      if (isNaN(valueAsNumber)) {
        return getMessage(
          'dxMessages.error_messages.type_number',
          undefined,
          value,
          values
        );
      }

      let decimalsCount = 0;
      if (valueAsNumber % 1 !== 0) {
        decimalsCount = value.toString().split('.')[1].length;
      }

      if (decimalsCount > decimals) {
        return getMessage(message, { decimals }, value, values);
      }
    }
);

/**
 * Checks to see if all values for the fields have the same line.
 * This is a multi-field operator.
 * It is used in a invoice/lines scenario with relations between same values but different lines.
 */
export const lineQuantitiesWithSameSign = memoize(
  (
      useSameSign,
      message = 'dxMessages.error_messages.quantities_use_same_sign'
    ) =>
    (value, values) => {
      if (value === undefined || values?.lines === undefined) {
        return;
      }

      if (values && useSameSign) {
        const allPositive = values?.lines?.every(
          (x) =>
            x?.invoicedQuantity?.value === undefined ||
            x?.invoicedQuantity?.value > 0
        );
        const allNegative = values?.lines?.every(
          (x) =>
            x?.invoicedQuantity?.value === undefined ||
            x?.invoicedQuantity?.value < 0
        );

        if (value === 0 || (!allPositive && !allNegative)) {
          return getMessage(message, undefined, value, values);
        }
      }
      return undefined;
    }
);

/**
 * Value cannot be zero
 */
export const nonZero = memoize(
  (nonZero, message = 'dxMessages.error_messages.non_zero') =>
    (value, values) => {
      if (nonZero && value !== undefined && (value === 0 || value === '0')) {
        return getMessage(message, undefined, value, values);
      }
      return undefined;
    }
);

/**
 * Value must be negative
 */
export const negative = memoize(
  (negative, message = 'dxMessages.error_messages.negative') =>
    (value, values) => {
      if (negative && value !== undefined && +value >= 0) {
        return getMessage(message, undefined, value, values);
      }
      return undefined;
    }
);

/**
 * Only numbers authorized
 */
export const numbersOnly = memoize(
  (decimals, message = 'dxMessages.error_messages.type_number') =>
    (value, values) => {
      if (value === undefined || value === null || value === '') {
        return;
      }

      var reg = new RegExp('^\\d+$');
      const valid = reg.test(value);

      if (!valid) {
        return getMessage(message, { decimals }, value, values);
      }
    }
);

/**
 * Value fixed to length 10
 */
export const fixedLength = memoize(
  (fixedLength, message = 'dxMessages.error_messages.fixed_length') =>
    (value, values) => {
      if (fixedLength && value !== undefined && value.length !== fixedLength) {
        return getMessage(message, { fixedLength }, undefined, undefined);
      }
      return undefined;
    }
);

export const regexMatch = memoize(
  (template: Template, params: RegexValidator) => (value, values) => {
    if (params !== undefined && params.regex && value !== null) {
      var reg = new RegExp(params.regex);
      const valid = reg.test(value);
      if (!valid) {
        return getMessage(params.message, undefined, undefined, undefined);
      }
    }
    return;
  }
);

/**
 * Validate function which compares
 * the value to the target one.
 */
const compareAny =
  (
    template: Template,
    target: string,
    message: string,
    compareType: CompareTypeOperator
  ) =>
  (value, allValues) => {
    if (
      allValues[target] &&
      value &&
      !compare2Values(value, allValues[target], compareType)
    ) {
      return getMessage(message, undefined, undefined, undefined);
    }
  };

const compare2Values = (
  val1: any,
  val2: any,
  compareType: CompareTypeOperator
): boolean => {
  let result = false;

  switch (compareType) {
    case CompareTypeOperator.LT:
      result = val1 < val2;
      break;
    case CompareTypeOperator.LTE:
      result = val1 <= val2;
      break;
    case CompareTypeOperator.GT:
      result = val1 > val2;
      break;
    case CompareTypeOperator.GTE:
      result = val1 >= val2;
      break;
    case CompareTypeOperator.EQ:
      result = val1 === val2;
      break;

    default:
      break;
  }

  return result;
};

export const compareDates = memoize(
  (template: Template, params: CompareValidator) => (value, values) => {
    if (value === undefined || value === null) {
      return;
    }

    let value2;
    if (params.target === CompareValidatorTargetToday) {
      const now = moment(new Date());
      value2 = now.format();
    } else {
      let targetField = getFieldFromTemplate(template, params.target);
      let targetSource = getSourceField(targetField?.source);
      value2 = targetSource ? get(values, targetSource) : undefined;
    }

    if (value2 === undefined || value2 === null) {
      return;
    }

    let compareType: CompareTypeOperator = CompareTypeOperator[params.operator];
    if (compareType) {
      const dateRange1 = resolveDateRange(value);
      const dateRange2 = resolveDateRange(value2);

      if (dateRange1 && dateRange2) {
        const date1To = new Date(dateRange1.to);
        date1To.setHours(0, 0, 0, 0); // hour information can falsify result

        const date2From = new Date(dateRange2.from);
        date2From.setHours(0, 0, 0, 0); // hour information can falsify result

        const val1 = date1To.getTime();
        const val2 = date2From.getTime();
        if (!compare2Values(val1, val2, compareType)) {
          return getMessage(params.message, undefined, value, values);
        }
      }
    }
  }
);

/**
 * Validates that a field is set.
 * Fields have to be on the same line or no one in a line.
 */
export const isSet = memoize(
  (template: Template, emitter: ValidationEmitter, params: IsSetValidator) =>
    (value: any, values) => {
      if (!value) {
        // any value types : null and undefined string + false boolean
        return;
      }
      let isEmitterInLinesTable: boolean = false;
      const lineIndex = getLineIndexFromSource(emitter.source || '');
      if (lineIndex !== -1) {
        isEmitterInLinesTable = true;
      }
      let targetField = getFieldFromTemplate(template, params.target);
      let targetSource = getSourceField(targetField?.source);
      if (isEmitterInLinesTable) {
        targetSource = `lines[${lineIndex}].${targetSource}`;
      }
      const targetValue = targetSource ? get(values, targetSource) : undefined;

      if (targetValue === undefined || targetValue === null) {
        return getMessage(params.message, undefined, value, values);
      }
    }
);
