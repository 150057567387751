import { Typography } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { Constants } from '../../constants';
import { getDxApplication, isWebResource } from '../../utils';
import { useChangeAttachmentsAllowed } from './AttachmentUtils';
import { ListType, Rows } from './Rows';
import {
  UploadFilesFieldWithPolicy,
  useAttachmentPolicy,
} from './upload-attachments';

export interface AttachmentRowsProps {
  standardColumns: any;
  basePath: any;
  resource: any;
  specificResource: any;
  showMetadataButton: any;
  downloadButton: any;
  previewButton: any;
  currentSortAttach: any;
  setSort: any;
  id: any;
  attachments: any;
  recipientId: any;
  processStatus: any;
  invoiceType: any;
  isLoading?: boolean;
  redirectOnDelete?: boolean;
  refreshOnDelete?: boolean;
  onDelete?: () => void;
  onReload?: () => void;
  readOnly?: boolean;
}

/**
 * Component with attachments list and upload control
 */
export const AttachmentRows = ({
  standardColumns,
  basePath,
  resource,
  specificResource,
  showMetadataButton,
  downloadButton,
  previewButton,
  currentSortAttach,
  setSort,
  id,
  attachments,
  recipientId,
  processStatus,
  invoiceType,
  isLoading,
  redirectOnDelete = true,
  refreshOnDelete = true,
  onDelete,
  onReload,
  readOnly = false,
}: AttachmentRowsProps) => {
  const [loading, attachPolicy, attachPolicyError] = useAttachmentPolicy(
    resource?.replace(/^web/, '').toLocaleLowerCase(),
    recipientId
  );
  const translate = useTranslate();

  const isAttachmentDeletionAllowed: boolean =
    attachPolicy?.codeAndStatuses?.[0].processStatus?.find(
      (s) => s === processStatus
    ) !== undefined;

  const allowChangeAttachments =
    useChangeAttachmentsAllowed(specificResource) && !readOnly;
  const urlPrefix =
    process.env.NODE_ENV === 'development' ? `${getDxApplication()}/` : '';
  return (
    <>
      {!!attachments?.length && (
        <Rows
          commonColumns={standardColumns}
          basePath={basePath}
          documents={attachments}
          resource={resource}
          showMetadataButton={showMetadataButton}
          downloadButton={downloadButton}
          previewButton={previewButton}
          setSort={(event) => setSort(event, 'attach')}
          currentSort={currentSortAttach}
          specificResource={specificResource}
          listType={ListType.ATTACH}
          isLoading={isLoading}
          redirectOnDelete={redirectOnDelete}
          refreshOnDelete={refreshOnDelete}
          onDelete={onDelete}
          isDeletionAllowed={isAttachmentDeletionAllowed}
        />
      )}
      {attachments && attachments?.length === 0 && (
        <Typography>
          {translate('dxMessages.attachments.no_docs', {
            _: 'No attachments',
          })}
        </Typography>
      )}
      {allowChangeAttachments && (
        <UploadFilesFieldWithPolicy
          loading={loading}
          attachPolicy={attachPolicy}
          attachPolicyError={attachPolicyError}
          postUrl={
            isWebResource(specificResource)
              ? `${urlPrefix}${Constants.RESOURCE_WEBDOCUMENT}/attach/${id}`
              : `${urlPrefix}attach/${id}`
          }
          resource={resource}
          recipientId={recipientId}
          processStatus={processStatus}
          invoiceType={invoiceType}
          existingAttachments={attachments}
          onReload={onReload}
        />
      )}
    </>
  );
};
