import {
  checkPermissions,
  Constants,
  DocumentTypeCode,
  InvoiceTypeCode,
  Metadata,
  RegulatorExtraDetailsType,
  TemplatesMetadata,
  Ubl,
  UserRoles,
} from '@dx-ui/dx-common';
import { cloneDeep, get, set } from 'lodash';
import { Record } from 'react-admin';
import {
  buildAddressAsAString,
  getDeliveryLocationAddress,
} from '../modules/common/PreviewAddress';
import { FieldTypes } from '../shared';
import {
  AlfrescoContent,
  DocumentProperties,
  flatten,
  P2pData,
  P2pUblContent,
  PeppolAccessPoint,
  Template,
  WebFormData,
} from '../shared/types';
import {
  getFieldFromSourceInTemplate,
  getFieldSourceOnPropertyValue,
  setFieldPropertyBy,
  setSourceField,
} from '../shared/webForms/utils';
import {
  DuplicationType,
  IAlfrescoDocumentService,
} from './AlfrescoDocumentService';
import { BaseDocumentService, DefaultCurrencyID } from './BaseDocumentService';
import { CatalogService } from './CatalogService';
import { CompanyService, CreationPolicy, P2P_STATUS } from './CompanyService';
import { DataHelpers } from './DataHelpers';
import {
  FormDataHelpers,
  LineProcessor,
  TaxAndPriceUtils,
} from './FormDataHelpers';
import { IDocumentService } from './IDocumentService';
import { TemplatesService } from './TemplatesService';

const TEMPLATE_TYPE = 'P2P';

/**
 *
 */
export interface IInvoiceService extends IDocumentService {
  getInvoices(filter: object): Promise<Record[]>;
}

export enum EFACTURA_FLAG {
  SENDEFACTURA = 'SENDEFACTURA',
  NOEFACTURA = 'NOEFACTURA',
  EFACTURAONLY = 'EFACTURAONLY',
}

/**
 *
 */
export class InvoiceService
  extends BaseDocumentService
  implements IInvoiceService
{
  constructor(
    private companyService: CompanyService,
    private catalogService: CatalogService,
    private templatesService: TemplatesService,
    documentService: IAlfrescoDocumentService
  ) {
    super(documentService);
  }

  public static LIMIT_FOR_ARRAYINPUT = 50;
  public static CUSTOM_CATEGORY = 'custom';
  public static PEPPOL_CUSTOMIZATION_ID =
    'urn:cen.eu:en16931:2017#compliant#urn:fdc:peppol.eu:2017:poacc:billing:3.0';
  public static PEPPOL_CUSTOMIZATION_ID_BE =
    'urn:cen.eu:en16931:2017#conformant#urn:UBL.BE:1.0.0.20180214';
  public static PEPPOL_PROFILE_ID =
    'urn:fdc:peppol.eu:2017:poacc:billing:01:1.0';

  /**
   *
   */
  public async loadDraft(nodeId: string | undefined): Promise<P2pData> {
    const result = await this.documentService.loadDocumentDraft(
      nodeId,
      DocumentTypeCode.INVOIC
    );

    const data = flatten(nodeId, result);
    // Ensure document level allowance/charge integrity if any
    TaxAndPriceUtils.ensureDocumentLevelAllowanceCharge(data);

    FormDataHelpers.recalculateTaxesAndPrices(data);
    this.setRequiredDataIfMissing(
      data,
      DocumentTypeCode.INVOIC,
      get(data, Ubl.invoiceTypeCode)
        ? get(data, Ubl.invoiceTypeCode)
        : InvoiceTypeCode.COMMERCIAL
    );
    return data;
  }

  public async load(nodeId: string): Promise<P2pUblContent> {
    const result = await this.documentService.loadDocument(
      nodeId,
      DocumentTypeCode.INVOIC
    );

    const lines = result.invoiceLine;
    delete result.invoiceLine;
    const ublProperties = result;

    return {
      lines,
      ublProperties,
    };
  }

  public async createFromOrder(nodeId: string): Promise<P2pData> {
    const result = await this.documentService.convertTo(
      nodeId,
      DocumentTypeCode.INVOIC
    );

    const data = flatten(result.id, result);
    FormDataHelpers.recalculateTaxesAndPrices(data);
    this.setRequiredDataIfMissing(
      data,
      DocumentTypeCode.INVOIC,
      InvoiceTypeCode.COMMERCIAL
    );
    return data;
  }

  public async createFromReceiptAdvice(nodeId: string): Promise<P2pData> {
    const result = await this.documentService.convertTo(
      nodeId,
      DocumentTypeCode.INVOIC
    );

    const data = flatten(result.id, result);
    FormDataHelpers.recalculateTaxesAndPrices(data);
    this.setRequiredDataIfMissing(
      data,
      DocumentTypeCode.INVOIC,
      InvoiceTypeCode.COMMERCIAL
    );
    return data;
  }

  public createNewContent(
    defaultCurrencyID: string = DefaultCurrencyID
  ): P2pData {
    const result: AlfrescoContent = {
      ...this.documentService.initNewDocument(DocumentTypeCode.INVOIC),
      ublContent: {
        lines: [],
        ublProperties: {},
      },
    };

    const data = flatten(undefined, result);
    this.setRequiredDataIfMissing(
      data,
      DocumentTypeCode.INVOIC,
      InvoiceTypeCode.COMMERCIAL,
      defaultCurrencyID // TODO: this is not correct - find another way for filling this default value
    );

    return data;
  }

  public async createNew(recipientId: string): Promise<string | undefined> {
    let invoiceContent: P2pData = await this.createNewContent();
    set(invoiceContent.properties, Metadata.recipientId, recipientId);
    const unflattenedInvoice = {
      properties: invoiceContent.properties,
      ublContent: {
        lines: invoiceContent.lines,
        ublProperties: invoiceContent.ublProperties,
      },
    };
    const invoiceProperties = await this.createOrUpdateDocument(
      undefined,
      unflattenedInvoice
    );
    return invoiceProperties.id;
  }

  public async clone(nodeId: string): Promise<P2pData> {
    const result = await this.documentService.copy(
      nodeId,
      DuplicationType.COPY
    );
    const data = flatten(result.id, result);
    FormDataHelpers.recalculateTaxesAndPrices(data);
    this.setRequiredDataIfMissing(
      data,
      DocumentTypeCode.INVOIC,
      InvoiceTypeCode.COMMERCIAL //we can't clone a non commercial invoice normally
    );
    return data;
  }

  public async createCancelInvoice(nodeId: string): Promise<P2pData> {
    const result = await this.documentService.copy(
      nodeId,
      DuplicationType.INVOIC_CANCELLATION
    );
    const data = flatten(result.id, result);
    FormDataHelpers.recalculateTaxesAndPrices(data);
    this.setRequiredDataIfMissing(
      data,
      DocumentTypeCode.INVOIC,
      InvoiceTypeCode.CANCELLATION
    );
    return data;
  }

  public async createCorrectiveInvoice(nodeId: string): Promise<P2pData> {
    const result = await this.documentService.copy(
      nodeId,
      DuplicationType.INVOIC_CORRECTIVE
    );

    const data = flatten(result.id, result);
    FormDataHelpers.recalculateTaxesAndPrices(data);
    this.setRequiredDataIfMissing(
      data,
      DocumentTypeCode.INVOIC,
      InvoiceTypeCode.CORRECTION
    );
    return data;
  }

  /**
   * Saves a despatch Advice document to alfresco
   */
  public async createOrUpdateDocument(
    nodeId: string | undefined,
    data: AlfrescoContent
  ): Promise<DocumentProperties> {
    return this.documentService.createOrUpdateDocument(nodeId, data);
  }

  private setRequiredDataIfMissing(
    data: P2pData,
    documentTypeCode?: DocumentTypeCode,
    invoiceTypeCode?: InvoiceTypeCode,
    currencyID?: string
  ) {
    if (documentTypeCode && !get(data.properties, Metadata.documentTypeCode)) {
      set(data.properties, Metadata.documentTypeCode, documentTypeCode);
    }

    if (invoiceTypeCode && !get(data, Ubl.invoiceTypeCode)) {
      set(data, Ubl.invoiceTypeCode, invoiceTypeCode);
    }

    if (
      invoiceTypeCode &&
      !get(data.properties, Metadata.documentSubTypeCode)
    ) {
      set(data.properties, Metadata.documentSubTypeCode, invoiceTypeCode);
    }

    if (currencyID && !get(data.properties, Metadata.currency)) {
      set(data.properties, Metadata.currency, currencyID);
    }

    set(data.properties, Metadata.readStatus, Constants.READ_STATUS_NEW);

    set(
      data.properties,
      Metadata.processDocumentFormatType,
      Constants.PROCESS_DOCUMENT_FORMAT_TYPE_DRAFT
    );

    // delivery location name
    if (!get(data.properties, Metadata.locationName)) {
      set(
        data.properties,
        Metadata.locationName,
        get(data, Ubl.deliveryLocationName)
      );
    }
    // delivery location GLN
    if (!get(data.properties, Metadata.gln)) {
      set(data.properties, Metadata.gln, get(data, Ubl.deliveryLocationGLN));
    }
    // delivery location address
    if (
      !get(data.properties, Metadata.locationAddress) ||
      get(data.properties, Metadata.locationAddress) !==
        buildAddressAsAString(getDeliveryLocationAddress(data))
    ) {
      set(
        data.properties,
        Metadata.locationAddress,
        buildAddressAsAString(getDeliveryLocationAddress(data))
      );
    }
  }

  public async getInvoices(filter: object): Promise<Record[]> {
    return await this.documentService.listDocuments(
      Constants.RESOURCE_INVOICE,
      filter
    );
  }

  public async fetchCreationPolicy(
    resource: string,
    recipientId: string
  ): Promise<CreationPolicy> {
    return await this.companyService.fetchCreationPolicy(resource, recipientId);
  }

  /**
   * Checks if the user has the permissions to create an invoice
   */
  public static canCreate(permissions: any): boolean {
    return (
      permissions &&
      checkPermissions(
        permissions,
        UserRoles.DXPURCHASE_PRODUCT,
        UserRoles.CREATE_INVOICE
      )
    );
  }

  /**
   * Gets or creates a document.
   * @param nodeId Alfresco node Id of the document
   * @param recipientId recipient Id of the document
   * @param locale
   * @param alfrescoMetadata - alfresco properties (edm:* fields)
   * @param newTemplate - optional : a template which can already be loaded by an onChange mechanism
   */
  public async getData(
    nodeId: string | undefined,
    recipientId: string,
    locale: string,
    alfrescoMetadata: any = {},
    newTemplate?: any
  ): Promise<WebFormData> {
    const completeData: P2pData = {
      id: undefined,
      properties: alfrescoMetadata,
      lines: [],
      ublProperties: {},
    };
    const selectValues: any[] = [];

    DataHelpers.setRecipientId(completeData, recipientId);

    let document: P2pData;

    if (newTemplate) {
      document = this.createNewContent();
    } else {
      document = await this.loadDraft(nodeId);
    }

    let documentTypeCode: DocumentTypeCode | undefined =
      DataHelpers.getDocumentTypeCode(document);
    let documentCategoryCode: string | undefined =
      DataHelpers.getInvoiceTypeCode(document) || InvoiceTypeCode.COMMERCIAL;
    let invoiceType: string | undefined = DataHelpers.getInvoiceType(document);

    let regulatorExtraDetails: RegulatorExtraDetailsType | undefined =
      DataHelpers.regulatorExtraDetails(completeData); // We need to keep this info even on template reload.

    if (documentTypeCode === undefined) {
      throw new Error('documentTypeCode cannot be undefined!');
    }

    // invoiceType can be undefined on purpose in case of a brand new document created from scratch.
    // Then it is set thanks to the answer of the webformtemplate/docsubtypes REST call
    const { data: invoiceTypeData, lists: invoiceTypeList } =
      await this.fillInvoiceType(
        documentTypeCode,
        documentCategoryCode,
        recipientId,
        invoiceType,
        regulatorExtraDetails
      );

    this.mergeFormData(selectValues, invoiceTypeList);
    this.mergeFormData(completeData, invoiceTypeData);

    // Sets the default CustomizationID => from edm:invoiceType
    DataHelpers.setCustomizationID(
      completeData,
      DataHelpers.getInvoiceType(invoiceTypeData)
    );

    let templateRecipientId = recipientId;
    if (RegulatorExtraDetailsType.ANAF_ONLY === regulatorExtraDetails) {
      templateRecipientId = 'EFACTURA';
      DataHelpers.setInvoiceType(completeData, Constants.INVOICE_TYPE_FMF);
    }
    if (RegulatorExtraDetailsType.PEPPOL === regulatorExtraDetails) {
      templateRecipientId = 'PEPPOL';
      DataHelpers.setInvoiceType(completeData, Constants.INVOICE_TYPE_FMF);
    }

    let template: any;
    if (newTemplate) {
      template = newTemplate;
    } else {
      template = await this.templatesService.getTemplate(
        documentTypeCode,
        DataHelpers.getInvoiceType(completeData),
        documentCategoryCode,
        templateRecipientId
      );
    }
    const { defaultValues: templateData, selectValues: templateLists } =
      this.templatesService.getValues(template);

    const { data: hardcodedData, lists: hardcodedLists } =
      this.getHardcodedValues(TEMPLATE_TYPE);

    // sets default
    this.mergeFormData(completeData, hardcodedData);
    this.mergeFormData(selectValues, hardcodedLists, true);
    this.mergeFormData(selectValues, templateLists);
    this.mergeFormData(completeData, templateData);

    // sets document data
    this.mergeFormData(completeData, document);

    //const databaseLists = await this.getDatabaseLists();

    // set dynamic data
    const { data, lists } = await this.getAdditionalInformation(
      template,
      locale,
      completeData
    );
    this.mergeFormData(completeData, data);
    this.mergeFormData(selectValues, lists, true);

    //this.mergeFormData(selectValues, databaseLists, true);  // TODO: see if we can localize and integrate this data

    if (completeData.lines.length === 0) {
      const newLine = FormDataHelpers.initNewInvoiceLine(
        DataHelpers.getCurrencyID(completeData)
      );
      completeData.lines = [newLine];
    }

    const supplierId = get(completeData.properties, Metadata.issuerId);
    const catalogLines = await this.catalogService.getCatalogLines(
      recipientId,
      supplierId
    );
    if (catalogLines) {
      const { lists: catalogLists } = await this.fillLinesSuggestionsInfo(
        catalogLines,
        Ubl.codeClient,
        Ubl.codeSupplier,
        Ubl.codeStandard,
        Ubl.description
      );
      this.mergeFormData(selectValues, catalogLists, true);
    }

    // Sets mono VAT fields if defined in the template
    const monoVatData = this.manageMonoVat(template, completeData);
    this.mergeFormData(completeData, monoVatData);

    // Sets tax category fields if defined in the template
    const taxCategoryData = this.manageTaxCategory(template, completeData);
    this.mergeFormData(completeData, taxCategoryData);

    const sgrTaxCategoryData = this.manageSGRTaxCategory(
      template,
      completeData
    );

    this.mergeFormData(completeData, sgrTaxCategoryData);

    const documentData: P2pData = {
      id: completeData.id,
      customFields: completeData.customFields,
      lines: completeData.lines,
      properties: completeData.properties,
      ublProperties: completeData.ublProperties,
    };

    // Optional behavior according to template definition
    let modifiedTemplate = cloneDeep(template);
    modifiedTemplate = this.manageExchangeRate(modifiedTemplate, documentData);

    // Efactura conditional display
    modifiedTemplate = await this.manageDisplayEFacturaSelect(
      modifiedTemplate,
      documentData
    );

    modifiedTemplate = this.manageDisplayEmail(modifiedTemplate, documentData);

    if (
      DataHelpers.regulatorExtraDetails(document) ===
      RegulatorExtraDetailsType.PEPPOL
    ) {
      const { peppolDefaultValues, peppolSelectValues } =
        this.setPeppolCountrySpecifcs(template, documentData);
      this.mergeFormData(selectValues, peppolSelectValues);
      this.mergeFormData(documentData, peppolDefaultValues);
    }

    return {
      documentData,
      selectValues,
      template: modifiedTemplate,
    };
  }

  /**
   * Checks if a template exists for user's data
   */
  public async getInvoiceTemplateRights(
    documentRecipientId: string,
    regulatorExtraDetails?: RegulatorExtraDetailsType
  ): Promise<{
    cloneOrCreate: boolean;
    cancel: boolean;
    corrective: boolean;
  }> {
    let cloneOrCreate = false;
    let cancel = false;
    let corrective = false;

    let invoiceSubTypes = await this.templatesService.getSubTypes(
      DocumentTypeCode.INVOIC,
      documentRecipientId,
      regulatorExtraDetails
    );

    cloneOrCreate =
      invoiceSubTypes?.filter(
        (r) => Object.values(r)[0] === InvoiceTypeCode.COMMERCIAL
      ).length > 0;

    cancel =
      invoiceSubTypes?.filter(
        (r) => Object.values(r)[0] === InvoiceTypeCode.CANCELLATION
      ).length > 0;

    corrective =
      invoiceSubTypes?.filter(
        (r) => Object.values(r)[0] === InvoiceTypeCode.CORRECTION
      ).length > 0;

    return { cloneOrCreate, cancel, corrective };
  }

  /**
   * Saves the data as an Alfresco document
   * @param formData Formatted document data
   */
  public async saveData(formData: P2pData): Promise<DocumentProperties> {
    const data = FormDataHelpers.prepareP2pDataForSave(formData);
    return await this.createOrUpdateDocument(formData.id, data);
  }

  /**
   * Retrieves additional data from the backend (suppliers, recipients, ...)
   */
  private async getAdditionalInformation(
    template: any,
    locale: any,
    document: P2pData
  ): Promise<{ data: any; lists: any }> {
    const data: any = {};
    const lists: any = {};
    const documentTypeCode: DocumentTypeCode | undefined =
      DataHelpers.getDocumentTypeCode(document);

    if (documentTypeCode === undefined) {
      throw new Error('documentTypeCode cannot be undefined!');
    }

    const recipientId: string | undefined =
      DataHelpers.getRecipientId(document);
    const deliveryGLN: string | undefined =
      DataHelpers.getDeliveryGLN(document);
    await this.fillIssuerInformation(data, lists, locale);
    await this.fillRecipientInformation(
      document,
      data,
      lists,
      documentTypeCode,
      recipientId,
      deliveryGLN,
      locale
    );

    // Checks if the template is defining Tax Representative addresses info
    if (
      getFieldFromSourceInTemplate(
        template,
        'ublProperties.taxRepresentativeParty.postalAddress.country.identificationCode.value'
      ) !== undefined
    ) {
      const countrySelectData = this.getCountriesSelectData('', locale);
      setSourceField(
        // selected country code
        lists,
        'ublProperties.taxRepresentativeParty.postalAddress.country.identificationCode.value',
        countrySelectData.choices
      );
      setSourceField(
        lists,
        'ublProperties.taxRepresentativeParty.postalAddress.country.identificationCode.value',
        countrySelectData.choices
      );
    }

    return { data, lists };
  }

  private async fillIssuerInformation(data: any, lists: any, locale: any) {
    const details = await this.companyService.getDetails();
    //if the issuer is unknow only fill the country info
    if (details === undefined) {
      const countrySelectData = this.getCountriesSelectData('', locale);
      setSourceField(
        // selected country code
        lists,
        Ubl.accountingSupplierCountryCode,
        countrySelectData.choices
      );
      return;
    }
    const features = await this.companyService.fetchCompanyFeatures();
    const efacturaEmail = features?.features?.includes('E-FACTURA EMAIL');
    // Efactura specific code for email
    if (efacturaEmail) {
      setSourceField(data, FieldTypes.EFACTURA_EMAIL_FLAG, efacturaEmail);
    }

    setSourceField(data, Metadata.issuerId, details.identification);
    setSourceField(data, Metadata.issuerName, details.name);
    if (
      details.billingAddress?.financialAccount !== undefined &&
      details.billingAddress?.financialAccount !== ''
    ) {
      setSourceField(
        data,
        Ubl.payeeIban,
        details.billingAddress?.financialAccount
      );
    }
    if (
      details.billingAddress?.bank !== undefined &&
      details.billingAddress?.bank !== ''
    ) {
      setSourceField(data, Ubl.payeeBank, details.billingAddress?.bank);
    }
    setSourceField(
      data,
      Ubl.accountingSupplierBuildingNumber,
      details.billingAddress?.buildingNumber
    );
    // HOT FIX : TO BE REVISITED ONCE WE HAVE ALL THE SPECS CLEARED
    // V2 DB Address workaround which is take into account on backend side could not fit issuer info.
    // B/E sets name = tb_address.street, street = tb_address.additionalstreet
    let street = details.billingAddress?.street;
    if (!street || street === '') {
      // Meaning that additionalstreet was empty or not defined
      // => reverts the B/E V2 DB workaround
      street = details.billingAddress?.name;
    }

    setSourceField(data, Ubl.accountingSupplierStreetName, street);
    setSourceField(
      data,
      Ubl.accountingSupplierCityName,
      details.billingAddress?.city
    );
    setSourceField(
      data,
      Ubl.accountingSupplierPostalZone,
      details.billingAddress?.postalCode,
      false
    );
    setSourceField(
      data,
      Ubl.accountingSupplierGLN,
      details.billingAddress?.gln,
      false
    );

    const countrySelectData = this.getCountriesSelectData(
      details.billingAddress?.idCountry,
      locale
    );

    setSourceField(
      // selected country code
      lists,
      Ubl.accountingSupplierCountryCode,
      countrySelectData.choices
    );
    setSourceField(
      // country selection list
      data,
      Ubl.accountingSupplierCountryCode,
      countrySelectData.defaultValue
    );
    setSourceField(
      data,
      Ubl.accountingSupplierCompanyID,
      details.registrationNumber,
      false
    );

    setSourceField(
      data,
      Ubl.accountingSupplierCorporateStockAmountValue,
      details.corporateStock?.amount,
      false
    );
    setSourceField(
      data,
      Ubl.accountingSupplierCorporateStockAmountCurrencyID,
      details.corporateStock?.currency,
      false
    );
  }

  private async fillRecipientInformation(
    document: P2pData,
    data: any,
    lists: any,
    documentTypeCode: DocumentTypeCode,
    recipientId: string | undefined,
    deliveryGLN: string | undefined,
    locale: any
  ) {
    const details = await this.companyService.getDetails(recipientId);

    // PEPPOL and EFACTURA use case
    if (
      DataHelpers.regulatorExtraDetails(document) ===
      RegulatorExtraDetailsType.PEPPOL
    ) {
      const accountingCustomerCountryCode = get(
        document,
        Ubl.accountingCustomerCountryCode
      );
      if (
        accountingCustomerCountryCode &&
        accountingCustomerCountryCode === 'BE'
      ) {
        // Belgium specific customization ID code
        DataHelpers.setCustomizationID(
          document,
          InvoiceService.PEPPOL_CUSTOMIZATION_ID_BE
        );
      } else {
        DataHelpers.setCustomizationID(
          document,
          InvoiceService.PEPPOL_CUSTOMIZATION_ID
        );
      }
      DataHelpers.setProfileID(document, InvoiceService.PEPPOL_PROFILE_ID);

      // Access point
      const endpoints: PeppolAccessPoint[] =
        await this.companyService.fetchEndpoints();
      if (endpoints !== undefined && endpoints.length !== 0) {
        const companyList = endpoints.map((endpoint) => {
          return {
            id: endpoint.id,
            name: endpoint.companyName,
          };
        });
        setSourceField(lists, FieldTypes.PEPPOL_COMPANY_NAME, companyList);
        setSourceField(
          data,
          Ubl.accountingCustomerGLN,
          document.ublProperties?.accountingCustomerParty?.party?.endpointID
            ?.value
        );
        setSourceField(
          data,
          Ubl.accountingCustomerGLNSchemeID,
          document.ublProperties?.accountingCustomerParty?.party?.endpointID
            ?.schemeID
        );
        if (
          document.ublProperties?.accountingCustomerParty?.party?.endpointID
            ?.schemeID !== undefined &&
          document.ublProperties?.accountingCustomerParty?.party?.endpointID
            ?.value !== undefined
        ) {
          // set the already chosen Access point
          setSourceField(
            data,
            FieldTypes.PEPPOL_ACCESS_POINT,
            document.ublProperties?.accountingCustomerParty?.party?.endpointID
              ?.schemeID +
              ':' +
              document.ublProperties?.accountingCustomerParty?.party?.endpointID
                ?.value
          );
          // set the related ID with our access point list
          const peppolRegistry = companyList.find(
            (e) => e.name === DataHelpers.getRecipientName(document)
          );
          setSourceField(
            data,
            FieldTypes.PEPPOL_COMPANY_NAME,
            peppolRegistry?.id
          );
        }
      }
    }

    if (
      data?.ublProperties?.accountingSupplierParty?.party?.partyLegalEntity?.[0]
        ?.corporateStockAmount?.value
    ) {
      setSourceField(
        data,
        Ubl.accountingSupplierCompanyLegalForm,
        data.ublProperties.accountingSupplierParty.party.partyLegalEntity[0]
          .corporateStockAmount.value
      );
    }

    //if the recipient is unknow we skip the autofill part only fill the country dropdown
    if (
      details === undefined ||
      DataHelpers.regulatorExtraDetails(document) ===
        RegulatorExtraDetailsType.ANAF_ONLY ||
      DataHelpers.regulatorExtraDetails(document) ===
        RegulatorExtraDetailsType.PEPPOL
    ) {
      const countrySelectData = this.getCountriesSelectData('', locale);
      setSourceField(
        // selected country code
        lists,
        Ubl.accountingCustomerCountryCode,
        countrySelectData.choices
      );
      setSourceField(
        lists,
        Ubl.deliveryLocationCountryCode,
        countrySelectData.choices
      );

      return;
    }

    setSourceField(data, Metadata.recipientName, details.name);
    setSourceField(data, Ubl.buyerCustomerGLN, details.billingAddress?.gln);
    setSourceField(
      data,
      Ubl.payerIban,
      details.billingAddress?.financialAccount
    );
    setSourceField(data, Ubl.payerBank, details.billingAddress?.bank);
    setSourceField(
      data,
      Ubl.accountingCustomerBuildingNumber,
      details.billingAddress?.buildingNumber,
      false
    );

    // HOT FIX : TO BE REVISITED ONCE WE HAVE ALL THE SPECS CLEARED
    // V2 DB Address workaround which is take into account on backend side could not fit issuer info.
    // B/E sets name = tb_address.street, street = tb_address.additionalstreet
    let street = details.billingAddress?.street;
    if (!street || street === '') {
      // Meaning that additionalstreet was empty or not defined
      // => reverts the B/E V2 DB workaround
      street = details.billingAddress?.name;
    }
    setSourceField(data, Ubl.accountingCustomerStreetName, street, false);
    setSourceField(
      data,
      Ubl.accountingCustomerCityName,
      details.billingAddress?.city
    );
    setSourceField(
      data,
      Ubl.accountingCustomerPostalZone,
      details.billingAddress?.postalCode,
      false
    );

    const countrySelectData = this.getCountriesSelectData(
      details.billingAddress?.idCountry,
      locale
    );

    setSourceField(
      // selected country code
      lists,
      Ubl.accountingCustomerCountryCode,
      countrySelectData.choices
    );
    setSourceField(
      // country choices list
      data,
      Ubl.accountingCustomerCountryCode,
      countrySelectData.defaultValue
    );
    setSourceField(
      data,
      Ubl.accountingCustomerCompanyID,
      details.registrationNumber,
      false
    );
    setSourceField(
      data,
      Ubl.accountingCustomerCorporateStockAmountValue,
      details.corporateStock?.amount,
      false
    );
    setSourceField(
      data,
      Ubl.accountingCustomerCorporateStockAmountCurrencyID,
      details.corporateStock?.currency,
      false
    );

    // delivery address information
    const shippingAddressItems = details.shippingAddress?.map((l) => {
      return {
        id: l.gln,
        name: l.name,
        street: l.street,
        buildingNumber: l.buildingNumber,
        city: l.city,
        countryCode: l.idCountry,
        postalCode: l.postalCode,
        gln: l.gln,
      };
    });
    // build selection box items
    const shippingAddressSelectItems = this.createSelectData(
      shippingAddressItems,
      'id',
      'name',
      false, // apply alternative default value
      deliveryGLN // default value
    );

    setSourceField(
      // selected delivery address name
      lists,
      Ubl.deliveryLocationName,
      shippingAddressSelectItems.choices
    );

    let shippingAddress = details.shippingAddress?.filter(
      (address) => address.gln === deliveryGLN
    )[0];
    if (shippingAddress) {
      setSourceField(data, Ubl.deliveryLocationGLN, shippingAddress?.gln);
      setSourceField(data, Ubl.deliveryLocationName, shippingAddress?.name);
      setSourceField(
        data,
        Ubl.deliveryLocationBuildingNumber,
        shippingAddress?.buildingNumber,
        false
      );
      setSourceField(
        data,
        Ubl.deliveryLocationStreetName,
        shippingAddress?.street,
        false
      );
      setSourceField(data, Ubl.deliveryLocationCityName, shippingAddress?.city);
      setSourceField(
        data,
        Ubl.deliveryLocationPostalZone,
        shippingAddress?.postalCode,
        false
      );
    }

    // delivery country list needs to always be computed
    const deliveryCountrySelectData = this.getCountriesSelectData(
      shippingAddress?.idCountry,
      locale
    );

    setSourceField(
      lists,
      Ubl.deliveryLocationCountryCode,
      deliveryCountrySelectData.choices
    );

    if (deliveryCountrySelectData.defaultValue) {
      setSourceField(
        data,
        Ubl.deliveryLocationCountryCode,
        deliveryCountrySelectData.defaultValue
      );
    }

    // issuer info given by the recipient
    const relations = await this.companyService.getRelations(documentTypeCode);
    const currentRelation = relations.filter(
      (r) => r.identification === recipientId
    )[0];

    // Check if the document already contains the supplier code
    const ublSupplierCode = get(
      document,
      Ubl.accountingSupplierCustomerAssignedID
    );
    setSourceField(
      data,
      Ubl.accountingSupplierCustomerAssignedID,
      ublSupplierCode ? ublSupplierCode : currentRelation?.supplierCode
    );

    // Efactura specific code
    setSourceField(data, FieldTypes.EFACTURA_FLAG, currentRelation?.efactura);
    if (!currentRelation?.efactura) {
      setSourceField(data, Ubl.efacturaFlag, EFACTURA_FLAG.NOEFACTURA);
    }
  }

  private async fillInvoiceType(
    documentTypeCode: string,
    invoiceTypeCode: string,
    recipientId: string | undefined,
    invoiceType: string | undefined,
    regulatorExtraDetails: RegulatorExtraDetailsType | undefined
  ): Promise<{ data: any; lists: any }> {
    const data: any = {};
    const lists: any = {};
    const invoiceTypes = await this.templatesService.getSubTypes(
      documentTypeCode,
      recipientId,
      regulatorExtraDetails
    );

    const invoiceTypeItems = invoiceTypes
      ?.filter((r) => Object.values(r)[0] === invoiceTypeCode)
      .map((r) => {
        const invoiceTypeCodeItem = Object.keys(r)[0];
        return {
          id: invoiceTypeCodeItem,
          name: `dxMessages.invoiceTypes.${invoiceTypeCodeItem}`,
        };
      });

    if (invoiceTypeItems?.length) {
      const invoiceTypeSelectItems = this.createSelectData(
        invoiceTypeItems,
        'id',
        'name',
        false,
        invoiceType || invoiceTypeItems[0].id
      );
      setSourceField(
        lists,
        Metadata.invoiceType,
        invoiceTypeSelectItems.choices
      );
      setSourceField(
        data,
        Metadata.invoiceType,
        invoiceType || invoiceTypeItems[0].id
      );
    }
    return { data, lists };
  }

  /**
   * Sets the required properties of exchange rate related fields (if exist) according to
   * currrency
   */
  private manageExchangeRate(
    modifiedTemplate: any,
    documentData: P2pData
  ): any {
    // Change fields properties according to currency current value
    const currency = DataHelpers.getCurrencyID(documentData);
    // Check if the template is defining at least the Caculation rate
    const paymentExchangeRateNodes =
      Ubl.paymentExchangeRateCalculationRate ===
      getFieldSourceOnPropertyValue(
        modifiedTemplate,
        'source',
        Ubl.paymentExchangeRateCalculationRate
      );
    let isRequired = false;
    if (currency && paymentExchangeRateNodes) {
      if (currency !== 'RON') {
        // TODO: currency value to test against should be extracted from the template
        isRequired = true;
      }
      modifiedTemplate = setFieldPropertyBy(
        modifiedTemplate,
        'source',
        Ubl.paymentExchangeRateSourceCurrencyCode,
        'required',
        isRequired
      );
      modifiedTemplate = setFieldPropertyBy(
        modifiedTemplate,
        'source',
        Ubl.paymentExchangeRateTargetCurrencyCode,
        'required',
        isRequired
      );
      modifiedTemplate = setFieldPropertyBy(
        modifiedTemplate,
        'source',
        Ubl.paymentExchangeRateCalculationRate,
        'required',
        isRequired
      );
    }
    return modifiedTemplate;
  }

  /**
   * Manages the display of the Efactura selection box based on FieldTypes.EFACTURA_FLAG
   * and if buyer's profile ask for ANAF only, set the select box if any and make it readOnly
   */
  private async manageDisplayEFacturaSelect(
    modifiedTemplate: any,
    documentData: P2pData
  ): Promise<any> {
    const efactura_flag = get(documentData, FieldTypes.EFACTURA_FLAG);
    // Checks if the template is defining the EFACTURA selection box
    const isEfacturaSelectDefined =
      Ubl.efacturaFlag ===
      getFieldSourceOnPropertyValue(
        modifiedTemplate,
        'name',
        FieldTypes.EFACTURA_SELECT
      );

    if (!efactura_flag && isEfacturaSelectDefined) {
      // EFACTURA selection box has to be hidden.
      // It's value is already set to "NOEFACTURA" by
      // fillRecipientInformation in that case.
      modifiedTemplate = setFieldPropertyBy(
        modifiedTemplate,
        'name',
        FieldTypes.EFACTURA_SELECT,
        'options',
        { hidden: true }
      );
      modifiedTemplate = setFieldPropertyBy(
        modifiedTemplate,
        'name',
        FieldTypes.EFACTURA_SELECT,
        'disabled',
        true
      );
    }
    if (isEfacturaSelectDefined) {
      const recipientId = DataHelpers.getRecipientId(documentData);
      // fetch Buyer's profile data
      const p2pStatus: P2P_STATUS = await this.companyService.fetchP2pStatus(
        Constants.RESOURCE_INVOICE,
        recipientId
      );
      if (p2pStatus === P2P_STATUS.NO_P2P) {
        // NO_P2P , force EFACTURAONLY and block the others choices
        setSourceField(
          documentData,
          Ubl.efacturaFlag,
          EFACTURA_FLAG.EFACTURAONLY
        );
        modifiedTemplate = setFieldPropertyBy(
          modifiedTemplate,
          'name',
          FieldTypes.EFACTURA_SELECT,
          'options',
          { readOnly: true }
        );
      }
    }
    return modifiedTemplate;
  }

  /**
   * Manages the display of the email input based on FieldTypes.EFACTURA_EMAIL
   */
  private manageDisplayEmail(
    modifiedTemplate: any,
    documentData: P2pData
  ): any {
    const efactura_email_flag = get(
      documentData,
      FieldTypes.EFACTURA_EMAIL_FLAG
    );
    // Checks if the template is defining the contact email input
    const isEfacturaEmailDefined =
      Ubl.accountingCustomerContactElectronicMail ===
      getFieldSourceOnPropertyValue(
        modifiedTemplate,
        'name',
        FieldTypes.EFACTURA_EMAIL
      );

    if (!efactura_email_flag && isEfacturaEmailDefined) {
      // contact email input has to be hidden.
      modifiedTemplate = setFieldPropertyBy(
        modifiedTemplate,
        'name',
        FieldTypes.EFACTURA_EMAIL,
        'options',
        { hidden: true }
      );
    }
    return modifiedTemplate;
  }

  /**
   * Sets the mono VAT field if defined in the template
   */
  private manageMonoVat(template: any, documentData: P2pData): any {
    const monoVatField = getFieldSourceOnPropertyValue(
      template,
      'source',
      FieldTypes.MONO_VAT
    );
    if (monoVatField === 'Unknown field') {
      return documentData;
    }
    const percentArray = documentData.lines
      .filter((l) => !LineProcessor.isLineSGR(l))
      .map((l) => l.taxTotal?.[0]?.taxSubtotal?.[0]?.percent?.value);
    const vatUndefined = percentArray.includes(undefined);
    const setPercentArray = new Set(percentArray);
    const samePercent = setPercentArray.size === 1;
    // In case of Mono VAT, sets monoVat if TaxSummary defines only one VAT
    if (samePercent && !vatUndefined) {
      setSourceField(
        documentData,
        FieldTypes.MONO_VAT,
        Array.from(setPercentArray)[0]
      );
    }
    return documentData;
  }

  /**
   * Sets the tax category field if defined in the template
   */
  private manageTaxCategory(template: any, documentData: P2pData): any {
    const taxCategoryField = getFieldSourceOnPropertyValue(
      template,
      'source',
      FieldTypes.TAX_CATEGORY
    );
    if (taxCategoryField === 'Unknown field') {
      return documentData;
    }

    const regulatorExtraDetails =
      DataHelpers.regulatorExtraDetails(documentData);

    const taxSchemeArray = documentData.lines
      .filter((l) => !LineProcessor.isLineSGR(l))
      .map((l) => {
        if (RegulatorExtraDetailsType.PEPPOL === regulatorExtraDetails) {
          return l.taxTotal?.[0]?.taxSubtotal?.[0]?.taxCategory?.taxScheme?.id
            ?.value;
        } else {
          return l.taxTotal?.[0]?.taxSubtotal?.[0]?.taxCategory?.taxScheme?.name
            ?.value;
        }
      });

    const taxSchemeUndefined = taxSchemeArray.includes(undefined);
    const setTaxSchemeArray = new Set(taxSchemeArray);
    const sameScheme = setTaxSchemeArray.size === 1;
    if (!sameScheme || taxSchemeUndefined) {
      set(documentData, FieldTypes.TAX_CATEGORY, '');
    } else {
      // Can update Tax Category field
      let taxScheme = Array.from(setTaxSchemeArray)[0];
      if (RegulatorExtraDetailsType.PEPPOL === regulatorExtraDetails) {
        if (taxScheme === Constants.TAX_SCHEME_ID_7) {
          taxScheme = Constants.TAX_SCHEME_ID_S;
        }
      }
      set(documentData, FieldTypes.TAX_CATEGORY, taxScheme);
    }
    return documentData;
  }

  /**
   * Sets the tax category nodes for SGR if SGR is defined in the template
   *
       <cac:TaxCategory>
          <cbc:TaxExemptionReasonCode>VATEX-EU-O</cbc:TaxExemptionReasonCode>
          <cbc:TaxExemptionReason>SGR</cbc:TaxExemptionReason>
          <cac:TaxScheme>
            <cbc:ID>7</cbc:ID>
            <cbc:Name>E</cbc:Name>
            <cbc:TaxTypeCode>VAT</cbc:TaxTypeCode>
          </cac:TaxScheme>
        </cac:TaxCategory>
   */
  private manageSGRTaxCategory(template: any, documentData: P2pData): any {
    const SGRField = getFieldSourceOnPropertyValue(
      template,
      'source',
      FieldTypes.SGR_LINE
    );
    if (SGRField === 'Unknown field') {
      return documentData;
    }

    documentData?.lines?.forEach((line) => {
      if (LineProcessor.isLineSGR(line)) {
        set(line, Ubl.taxSchemeID, '7');
        set(line, Ubl.taxSchemeName, 'E');
        set(line, Ubl.taxCategoryCode, 'VAT');
        set(line, Ubl.taxExemptionReasonCode, 'VATEX-EU-O');
        set(line, Ubl.taxExemptionReason, Constants.SGR_REASON);
        set(line, FieldTypes.SGR_LINE, true);
      }
    });
    return documentData;
  }

  /**
   * Gets the "template metadata".
   * V2 data like currency etc. Currently this does not work due to i18n issues.
   */
  private async getDatabaseLists(): Promise<any> {
    const metadata = await this.templatesService.getTemplatesMetadata();
    this.alterP2pTemplateMetadata(metadata);
    return metadata;
  }

  /**
   * Sets the taxCategory, taxExemptionReasonCode, VAT and MONO VAT choices and default values from PEPPOL template data
   * @param template
   * @param documentData
   * @returns defaultValues and SelectValues specific to PEPPOL
   */
  private setPeppolCountrySpecifcs(template: Template, documentData: P2pData) {
    let countryCode = get(documentData, Ubl.accountingCustomerCountryCode);
    let countryCodeOrDefault = countryCode;
    const countryCodeField = getFieldFromSourceInTemplate(
      template,
      Ubl.accountingCustomerCountryCode
    );
    const onChangeFunctionName: any = countryCodeField?.onChangeDo;
    const onChangeFunction: any = template.onChangeFunctions.find(
      (o) => o.name === onChangeFunctionName
    );

    // Retrieves the info from the template
    const taxCategoryField = getFieldFromSourceInTemplate(
      template,
      Ubl.taxSchemeName
    );
    const taxExemptionReasonCodeField = getFieldFromSourceInTemplate(
      template,
      Ubl.taxExemptionReasonCode
    );
    const vatField = getFieldFromSourceInTemplate(template, Ubl.vatPercentage);

    const selectValues = {};
    const defaultValues = {};

    // Check country Code targeted
    countryCodeOrDefault = countryCode;
    if (
      !onChangeFunction?.taxCategories.find(
        (f) => f.countryCode === countryCode
      )?.selectValues
    ) {
      // country specific info are not defined in the template for Tax category,
      // set default value (EU)
      countryCodeOrDefault = 'EU';
    }
    set(
      selectValues,
      `${taxCategoryField?.source}`,
      onChangeFunction?.taxCategories.find(
        (f) => f.countryCode === countryCodeOrDefault
      )?.selectValues
    );
    set(
      defaultValues,
      `${taxCategoryField?.source}`,
      onChangeFunction?.taxCategories?.find(
        (f) => f.countryCode === countryCodeOrDefault
      )?.defaultValue
    );

    // Check country Code targeted
    countryCodeOrDefault = countryCode;
    if (
      !onChangeFunction?.taxExemptionReasonCodes.find(
        (f) => f.countryCode === countryCode
      )?.selectValues
    ) {
      // country specific info are not defined in the template for Tax exemption Reason Code,
      // set default value (EU)
      countryCodeOrDefault = 'EU';
    }

    set(
      selectValues,
      `${taxExemptionReasonCodeField?.source}`,
      onChangeFunction?.taxExemptionReasonCodes.find(
        (f) => f.countryCode === countryCodeOrDefault
      )?.selectValues
    );
    set(
      defaultValues,
      `${taxExemptionReasonCodeField?.source}`,
      onChangeFunction?.taxExemptionReasonCodes.find(
        (f) => f.countryCode === countryCodeOrDefault
      )?.defaultValue
    );

    // Check country Code targeted
    countryCodeOrDefault = countryCode;
    if (
      !onChangeFunction?.vat.find((f) => f.countryCode === countryCode)
        ?.selectValues
    ) {
      // country specific info are not defined in the template for VAT,
      // set default value (EU)
      countryCodeOrDefault = 'EU';
    }

    const vatSelected = onChangeFunction?.vat.find(
      (f) => f.countryCode === countryCodeOrDefault
    );
    set(selectValues, `${vatField?.source}`, vatSelected?.selectValues);
    set(defaultValues, `${vatField?.source}`, vatSelected?.defaultValue);

    set(selectValues, FieldTypes.MONO_VAT, vatSelected?.selectValues);

    return {
      peppolDefaultValues: defaultValues,
      peppolSelectValues: selectValues,
    };
  }

  /**
   * Matchs the template metadata response content to valid template keys.
   */
  private alterP2pTemplateMetadata(templatesMetadata: object) {
    const translatedMetadata = { properties: {} };
    Object.keys(templatesMetadata).forEach((metadata) => {
      if (metadata === TemplatesMetadata.tax) {
        templatesMetadata['tax'].forEach(
          (taxEntry) => (taxEntry.value = parseFloat(taxEntry.value))
        );
        translatedMetadata[Ubl.vatPercentage] = templatesMetadata['tax']
          .map((taxEntry) => taxEntry)
          .sort((a, b) => (a.value > b.value ? 1 : a.value < b.value ? -1 : 0));
      }

      if (metadata === TemplatesMetadata.currency) {
        // currency : changes the key name and sorts the entries.
        translatedMetadata.properties[Metadata.currency] = templatesMetadata[
          metadata
        ]
          .map((currencyEntry) => currencyEntry)
          .sort((a, b) => (a.value > b.value ? 1 : a.value < b.value ? -1 : 0));
      }
    });
    return translatedMetadata;
  }
}
